<template>
	<v-card flat color="transparent" class="mx-auto mt-5" rounded="xl">
		<v-card-title class="my-3">
			<span class="text-h5">{{ $t('profile.brandTalentPool') }}</span>
		</v-card-title>
		<v-card-text>
			<v-row>
				<v-col cols="12" md="6" sm="12">
					<p class="text-body-1 text-justify" style="max-width: 90%">
						{{ $t('settings.talentPoolHelp1') }}
					</p>
					<p class="text-body-1 text-justify" style="max-width: 90%">
						{{ $t('settings.talentPoolHelp2') }}
					</p>
				</v-col>
				<v-col cols="12" md="6" sm="12">
					<v-form ref="form" v-model="valid">
						<v-checkbox v-model="talentPool" :label="$t('auth.agreeToTalentPool')" />
					</v-form>
				</v-col>
			</v-row>
		</v-card-text>

		<v-card-actions>
			<v-spacer />
			<v-btn x-large rounded class="px-4" color="primary" @click="callChangeTalentPool()">
				{{ $t('settings.update') }}
			</v-btn>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
	name: 'SettingsChangeTalentPool',
	data() {
		return {
			valid: false,
			talentPool: null,
		}
	},
	computed: {
		...mapGetters({
			currentUser: 'user/currentUser',
		}),
	},
	created() {
		let delay = this.currentUser.email != null ? 100 : 1000
		setTimeout(() => {
			this.talentPool = this.currentUser.talentPool
		}, delay)
	},
	methods: {
		callChangeTalentPool() {
			this.$refs.form.validate()
			if (!this.valid) return
			this.changeTalentPool({
				talentPool: this.talentPool,
			})
		},
		...mapActions('settings', ['changeTalentPool']),
	},
}
</script>
